import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../api';
import { useAuth } from './AuthContext';
import { useToast } from './ToastContext';
import Loader from '../Components/Loader/Loader';

interface BalanceContextProps {
  balance: number;
  isPopupOpen: boolean;
  togglePopup: () => void;
  fetchBalance: () => void;
  error: string
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const BalanceContext = createContext<BalanceContextProps | undefined>(undefined);

export const BalanceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [balance, setBalance] = useState<number>(0);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('')
  const { isAuthenticated } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const { notifySuccess, notifyError } = useToast();

  const togglePopup = () => {
    setIsPopupOpen(prevState => !prevState);
  };

  const fetchBalance = async () => {
    setLoading(true)

    try {
      const response = await api.get(`/company/balance`);
      setBalance(response.data.balance);
      setLoading(false)
    } catch (error) {
      console.log(error);
      notifyError('Ошибка получения баланса');
      setLoading(false)
    }
  };

  useEffect(() => {
    if(!isAuthenticated) {
      return
    }

    fetchBalance();
  }, []);

  if(loading) {
    return <Loader />
  }

  return (
    <BalanceContext.Provider value={{ balance, isPopupOpen, togglePopup, fetchBalance, error, setError }}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  const context = useContext(BalanceContext);
  if (!context) {
    throw new Error("useBalance must be used within a BalanceProvider");
  }
  return context;
};
