import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"

import './App.css';

// Components
import Login from './Components/Login/Login';
import PrivateRoute from './Components/PrivateComponent/PrivateComponent';
import { AuthProvider } from './Context/AuthContext';
import Account from './Components/Account/Account';
import Ads from './Components/Ads/Ads';
import Header from './Components/Header/Header';
import Responses from './Components/Responses/Responses';
import BalancePopup from './Components/BalancePopup/BalancePopup';
import { BalanceProvider } from './Context/BalanceContext';
import { ToastProvider } from './Context/ToastContext';
import { LoadingProvider } from './Context/LoadingContext';

function App() {

  return (
    <LoadingProvider>
    <ToastProvider>
      <AuthProvider>
        <BalanceProvider>
          <Header />
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Navigate to="/ads" />} />
              <Route
                path="/account"
                element={<PrivateRoute element={<Account />} />}
              />
              <Route
                path="/ads"
                element={<PrivateRoute element={<Ads />} />}
              />
              <Route
                path="/responses"
                element={<PrivateRoute element={<Responses me={false}/>} />}
              />
              <Route
                path="/contacts"
                element={<PrivateRoute element={<Responses me={true}/>} />}
              />
              <Route
                path="*"
                element={<div>404 - Page Not Found</div>} 
              />
            </Routes>
          </Router>
          <BalancePopup />
        </BalanceProvider>
      </AuthProvider>
    </ToastProvider>
    </LoadingProvider>
  );
}

export default App;
