import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

// Context
import { useAuth } from '../../Context/AuthContext';

// Components
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout';

interface PrivateRouteProps {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { isAuthenticated, isAuthChecked } = useAuth();

  if (!isAuthChecked) {
    return null; // Можно добавить индикатор загрузки
  }

  return isAuthenticated ? (
    <AuthenticatedLayout>
      {element}
    </AuthenticatedLayout>
  ) : <Navigate to="/login" />;
};

export default PrivateRoute;
