import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';

interface AuthContextType {
    isAuthenticated: boolean;
    isAuthChecked: boolean;
    login: (token: string) => void;
    logout: () => void;
  }
  
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);

      // Проверка токена при инициализации
    useEffect(() => {
        const token = localStorage.getItem('cleanetAuthToken');
        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false)
        }

        setIsAuthChecked(true);
    }, []);
  
    const login = (token:string) => {
        localStorage.setItem('cleanetAuthToken', token);
        setIsAuthenticated(true)
    }

    const logout = () => {

        localStorage.removeItem('cleanetAuthToken');
        setIsAuthenticated(false);
        window.location.href = '/login';
    }

    if (!isAuthChecked) {
      // Отображать что-то (например, лоадер) пока проверка не завершена
      return <div>Loading...</div>;
    }
  
    return (
      <AuthContext.Provider value={{ isAuthenticated, isAuthChecked, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};