import React, { useState } from 'react'
import { useBalance } from '../../Context/BalanceContext'
import { useToast } from '../../Context/ToastContext'
import api from '../../api'
import Loader from '../Loader/Loader'

// CSS
import "./BalancePopup.css"


const BalancePopup: React.FC = () => {
  const { isPopupOpen, togglePopup, error } = useBalance();
  const { notifySuccess, notifyError } = useToast();
  const [balance, setBalance] = useState<string | undefined> ('')
  const [loading, setLoading] = useState<boolean>(false)

  const inputHadler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target
    
    // Нужно не давать вводить символы кроме цифр
    value =  value.replace(/[^\d]/g, '');

    setBalance(value)

  }

  const requestBalance = async () => {
    setLoading(true)

    try {
      const response = await api.post('/company/balance', {amount: balance})
      setLoading(false)
      togglePopup()
      notifySuccess('Запрос на пополнение баланса успешно отправлен!')

    } catch (error) {
      setLoading(false)
      console.log(error)
      notifyError('Произошла ошибка при отправке запроса, попробуйте позже')

    }
  }

  if (!isPopupOpen) return null;


  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="close-popup" onClick={togglePopup}>×</div>
        {loading ? <Loader /> : (
          <>
            <div className="error-msg">{error}</div>
            <label htmlFor="balance">Пополнить счёт</label>
            <input type="text" name='balance' placeholder='Сумма' value={balance} onChange={inputHadler}/>
            <div className="button" onClick={requestBalance}>Запросить счёт</div>
          </>
        )}
      </div>
    </div>
  )
}

export default BalancePopup;
