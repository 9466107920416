import React, { useEffect, useState } from 'react'
import api from '../../api'
import { useBalance } from '../../Context/BalanceContext';

// Images
import crossImg from '../../assets/images/cross.svg'

// CSS
import "./Balance.css"



const Balance: React.FC = () => {
    const { balance, togglePopup } = useBalance();


    return (
            <div className="balance-wrapper">
                <div className="ammount">
                    <div className="counter">{balance} руб.</div>
                    <span>Баланс</span>
                </div>
                <div className="add-balance-button" onClick={togglePopup}>
                    <img src={crossImg} alt="" />
                </div>
            </div>
    )
}

export default Balance
