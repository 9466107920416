import React, { useEffect, useState } from 'react'
import api from '../../api'
import Address from '../Address/Address'
import { useToast } from '../../Context/ToastContext'
import Loader from '../Loader/Loader'
import DatePicker from 'react-datepicker'
import SelectField from '../Select/SelectField'

// CSS
import "./DetailsBar.css"
import 'react-datepicker/dist/react-datepicker.css';

// Images
import backIco from '../../assets/images/back_ico.svg'

export interface IIntAd {
    adType: number;
    startDate: Date;
    endDate: Date;
    timeFrom: string;
    timeTo: string
    experience: number;
    nation: number[]
    sex: number
    shift: number[];
    roomType: number;
    address: string;
    coordinates: {lat:number, lon: number};
    pickPoint: string
    rusRequired: number;
    clothes: number;
    hotel: number
    food: number
    patent: number
    transfer: number
    insurance: number
    notes: string;
    cityId: number;
    daySalary: string
    monthSalary: string
    metro: string
    isArchived: boolean
}

interface DetailsBarProps {
  ad?: number;
  isActive: boolean;
  setActiveAd: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSideBarIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshAds: React.Dispatch<React.SetStateAction<number>>;
  refreshAds: number
}

const DetailsBar: React.FC<DetailsBarProps> = (props:DetailsBarProps) => {
  
  const initialState = {
    adType: 0,
    startDate: new Date(),
    endDate: new Date(),
    timeFrom: "9:00",
    timeTo: "19:00",
    experience: 0,
    nation: [8],
    sex: 0,
    shift: [],
    roomType: 0,
    address: '',
    coordinates: {lat:0, lon: 0},
    pickPoint: '',
    rusRequired: 0,
    clothes: 0,
    hotel: 0,
    food: 0,
    patent: 0,
    transfer: 0,
    insurance: 0,
    notes: '',
    cityId: 0,
    daySalary: '',
    monthSalary: '',
    metro: '',
    isArchived: false
  }

  const [formData, setFormData] = useState<IIntAd>(initialState)
  const [updateMode, setUpdateMode] = useState<boolean>(false)
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const { notifySuccess, notifyError } = useToast();
  const [loading, setLoading] = useState<boolean>(false)

  // Динамические поля
  const [nameOptions, setNameOptions] = useState<{value: number, label: string}[]>([])

  // Получаем динамические поля
  useEffect(()=>{
    const fetchOptions = async () => {
      setLoading(true)
  
      try {
        const result = await api.get('/company/adNames')
        setNameOptions(result.data)
        setLoading(false)
      } catch (error) {
        notifyError('Ошибка при получении возможных имен объявления')
        console.log(error)
        setLoading(false)
      }
    }
  
    fetchOptions()
  },[])

  useEffect(()=> {
    setFormData(initialState)
    setUpdateMode(false)

    if(props.ad) {
      const fetchAd = async () => {
        setLoading(true)

        try {
          let response = await api.get(`/company/ad/${props.ad}`)
          const data = response.data;

          // Преобразуем строки в Date объекты
          const adWithDates = {
            ...data,
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
          };


          setLoading(false)
          setFormData(adWithDates)
          setUpdateMode(true)
        } catch (error) {
          console.log(error)
          notifyError('Ошибка при загрузке объявления')
          setLoading(false)
        }
      }

      fetchAd()
    }
  },[props.ad])

  const timeToDate = (time: string): Date => {
    const [hours, minutes] = time.split(':').map(Number);
    const now = new Date(new Date().setHours(hours, minutes,0,0));
    
    return now;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, geoLat?: number, geoLon?: number) => {
    const { name, value } = e.target;
    if (name === 'startDate' || name === 'endDate') {
      setFormData({ ...formData, [name]: new Date(value) });
    } else if(name === 'address' && geoLat && geoLon) {
      setFormData({ ...formData, [name]: value, coordinates: {lat: +geoLat, lon: +geoLon}});
    } else if(name === 'daySalary' || name === 'monthSalary') {
      const cleanedValue = value.replace(/\D/g, '');  // Remove non-numeric characters
      setFormData({ ...formData, [name]: cleanedValue });
    } else if(name === 'adType') {
        setFormData({ ...formData, [name]: +value }); // TODO Костыль с тайпом
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  function datesHandler(e: Date | null, name:string) {
    setFormData({ ...formData, [name]: e });
  }

  function timeHandler(e: Date | null, name:string) {

    // Функция для форматирования времени в строку
    const dateToTime = (date: Date | null): string => {
      if (!date) return '';
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }

    setFormData({ ...formData, [name]: dateToTime(e) });
  }

  const handleSubmit = async () => {
    let formErrors: { [key: string]: boolean } = {};

    if (formData.adType === 0 && formData.daySalary === '' ) {
      formErrors.daySalary = true;
    }

    if (formData.adType === 1 && formData.monthSalary === '' ) {
      formErrors.daySalary = true;
    }
  
    if (formData.address === '') {
      formErrors.address = true;
    }
  
    setErrors(formErrors);
  
    // Если есть ошибки, не отправляйте форму
    if (Object.keys(formErrors).length > 0) {
      notifyError('Не заполнены обязательные поля!');
      return;
    }

    setLoading(true)

    try {
      await api.post('/company/ad', formData);

      props.setRefreshAds(props.refreshAds + 1)
      props.setSideBarIsOpened(false)
      notifySuccess('Сохранено')
      setLoading(false)
    } catch (error) {
      notifyError("Ошибка при сохранении объявления:")
      setLoading(false)
    }
  };

  const handleUpdate = async (id:number) => {
    if(!id || id === 0) {
      return
    }

    setLoading(true)

    try {
      const response = await api.put(`/company/ad/${id}`, formData);
      const data = response.data

      // Преобразуем строки в Date объекты
      const adWithDates = {
        ...data,
        startDate: new Date(data.startDate),
        endDate: new Date(data.endDate),
      };

      setLoading(false)
      setFormData(adWithDates)
      props.setActiveAd(adWithDates.id)
      props.setRefreshAds(props.refreshAds + 1)
      props.setSideBarIsOpened(false)

      notifySuccess('Сохранено')
    } catch (error) {
      setLoading(false)
      notifyError("Ошибка при сохранении объявления:");
    }
  }

  const archiveAd = async (id: number) => {
    if (!id || id === 0) {
      return;
    }

    // Показываем системное окно подтверждения
    const isConfirmed = window.confirm('Вы уверены, что хотите архивировать это объявление?');

    if (isConfirmed) {
      setLoading(true)

      try {
        const response = await api.post(`/company/archive/${id}`);
        props.setRefreshAds(props.refreshAds + 1);
        props.setSideBarIsOpened(false);
        setLoading(false)
      } catch (error) {
        console.error('Ошибка при архивировании объявления:', error);
        notifyError('Ошибка при архивировании объявления');
        setLoading(false)
      }
    }
  }

  return (
    <div className={`details-bar-wrapper ${props.isActive ? 'open' : ''}`}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="details-bar-back-button">
            <img
              src={backIco}
              alt="back"
              onClick={() => props.setSideBarIsOpened(false)}
            />
          <div className={props.ad ? 'ad-id' : ''}>{props.ad ? `id: ${props.ad}` : ''}</div>
          </div>
          <div className="details-bar-content">
            <div className="row">
              <div className="type short">
                <label htmlFor="adType">Тип работ</label>
                <select
                  name="adType"
                  onChange={handleInputChange}
                  value={formData.adType}
                  disabled={loading}
                >
                  <option value="0">Разовая</option>
                  <option value="1">Постоянная</option>
                </select>
              </div>
              <div className="salary short">
                <label htmlFor={formData.adType === 0 ? 'daySalary' : 'monthSalary'}>{formData.adType === 0 ? 'Зарплата за смену' : 'Зарпалата за месяц'}</label>
                <input
                  className={errors.address ? 'input-error' : ''}
                  type="text"
                  name={formData.adType === 0 ? 'daySalary' : 'monthSalary'}
                  onChange={handleInputChange}
                  value={formData.adType === 0 ? formData.daySalary : formData.monthSalary} //TODO ВЫпилить костыли эти
                  disabled={loading}
                />
              </div>
            </div>

            {formData.adType === 0 ? (
                          <div className="row">
                          <div className="date short">
                            <label htmlFor="startDate">Дата начала</label>
                            <DatePicker 
                               selected={formData.startDate}
                               onChange={(date) => {datesHandler(date, 'startDate')}}
                               className='picker-input'
                               name="startDate"
                               dateFormat="dd/MM/yyyy"
                               selectsStart
                               startDate={formData.startDate}
                               endDate={formData.endDate}
                               disabled={loading}
                            />
            
                          </div>
                          <div className="date short">
                            <label htmlFor="endDate">Дата окончания</label>
                            <DatePicker 
                               selected={formData.endDate}
                               onChange={(date) => {datesHandler(date, 'endDate')}}
                               className='picker-input'
                               name='endDate'
                               dateFormat="dd/MM/yyyy"
                               selectsStart
                               startDate={formData.startDate}
                               endDate={formData.endDate}
                               disabled={loading}
                            />
                          </div>
                        </div>
            ) : ''}
  

  
            <div className="row">
              <div className="time-from short">
                <label htmlFor="timeFrom">Время от</label>
                <DatePicker 
                  selected={timeToDate(formData.timeFrom)}
                  onChange={(date) => {timeHandler(date, 'timeFrom')}}
                  name='timeFrom'
                  className='picker-input'
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Время"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                />
              </div>
              <div className="time-to short">
                <label htmlFor="timeTo">Время до</label>
                <DatePicker 
                  selected={timeToDate(formData.timeTo)}
                  onChange={(date) => {timeHandler(date, 'timeTo')}}
                  name='timeTo'
                  className='picker-input'
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Время"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                />
              </div>
            </div>

            {formData.adType === 1 ? (
                          <div className="row">
                          <div className="shift long">
                            <label htmlFor="shift">График работ</label>
            
                            <SelectField<IIntAd> options={[
                              {value: '1', label:'2/2'},
                              {value: '2', label:'6/1'},
                              {value: '3', label:'7/0'},
                              {value: '4', label:'5/2'},
                              {value: '0', label:'Другой'},
                            ]}
            
                            data={formData}
                            setData={setFormData}
                            name='shift'
                            loading={loading}
                            />
                          </div>
                        </div>
            ) : ''}
  


  
            <div className="row">
              <div className="sex short">
                <label htmlFor="sex">Пол</label>
                <select
                  name="sex"
                  onChange={handleInputChange}
                  value={formData.sex}
                  disabled={loading}
                >
                  <option value="1">Мужчина</option>
                  <option value="2">Женщина</option>
                  <option value="0">Не важно</option>
                </select>
              </div>
  
              <div className="nation short">
                <label htmlFor="nation">Национальность</label>
                <SelectField<IIntAd> options={[
                  {value: '0', label:'Россия'},
                  {value: '1', label:'Казахстан'},
                  {value: '2', label:'Таджикистан'},
                  {value: '3', label:'Узбекистан'},
                  {value: '4', label:'Киргизия'},
                  {value: '5', label:'Белоруссия'},
                  {value: '6', label:'Украина'},
                  {value: '7', label:'Армения'},
                  {value: '8', label:'Не важно'}
                ]}

                data={formData}
                setData={setFormData}
                name='nation'
                loading={loading}
                />
              </div>
            </div>
  
            <div className="row">
              <div className="experience long">
                <label htmlFor="experience">Опыт</label>
                <select
                  name="experience"
                  onChange={handleInputChange}
                  value={formData.experience}
                  disabled={loading}
                >
                  <option value="0">До 1 года</option>
                  <option value="1">1 год</option>
                  <option value="2">2 года</option>
                  <option value="3">3 года</option>
                  <option value="4">4 года</option>
                  <option value="5">5 лет</option>
                  <option value="6">Более 5 лет</option>
                </select>
              </div>
            </div>
  
            <div className="row">
              <div className="roomType long">
                <label htmlFor="roomType">Тип помещения</label>
                <select
                  name="roomType"
                  onChange={handleInputChange}
                  value={formData.roomType}
                  disabled={loading}
                >
                  {nameOptions.map(option => (<option value={option.value}>{option.label}</option>))}
                </select>
              </div>
            </div>

            <Address
              inputErrors={errors.address}
              handleInputChange={handleInputChange}
              value={formData.address}
              name={"address"}
              label={"address"}
              title={"Адрес работ"}
            />
  
            <Address
              handleInputChange={handleInputChange}
              value={formData.pickPoint}
              name={"pickPoint"}
              label={"pickPoint"}
              title={"Место сбора персонала (заполнить, если отличается от адреса работы)"}
            />
  
            <div className="row">
              <div className="rusRequired short">
                <label htmlFor="rusRequired">Знание русского языка</label>
                <select
                  name="rusRequired"
                  onChange={handleInputChange}
                  value={formData.rusRequired}
                  disabled={loading}
                >
                  <option value="1">Да</option>
                  <option value="0">Нет</option>
                </select>
              </div>
  
              <div className="clothes short">
                <label htmlFor="clothes">Спецодежда</label>
                <select
                  name="clothes"
                  onChange={handleInputChange}
                  value={formData.clothes}
                  disabled={loading}
                >
                  <option value="1">Предоставляется</option>
                  <option value="0">Не предоставляется</option>
                  <option value="2">Компенсируется</option>
                </select>
              </div>
            </div>
  
            <div className="row">
              <div className="hotel short">
                <label htmlFor="hotel">Проживание</label>
                <select
                  name="hotel"
                  onChange={handleInputChange}
                  value={formData.hotel}
                  disabled={loading}
                >
                  <option value="1">Предоставляется</option>
                  <option value="0">Не предоставляется</option>
                  <option value="2">Компенсируется</option>
                  <option value="3">Частично компенсируется</option>
                </select>
              </div>
  
              <div className="food short">
                <label htmlFor="food">Питание</label>
                <select
                  name="food"
                  onChange={handleInputChange}
                  value={formData.food}
                  disabled={loading}
                >
                  <option value="1">Предоставляется</option>
                  <option value="0">Не предоставляется</option>
                  <option value="2">Компенсируется</option>
                  <option value="3">Частично компенсируется</option>
                </select>
              </div>
            </div>
  
            <div className="row">
              <div className="patent long">
                <label htmlFor="patent">Оплата оформления патента</label>
                <select
                  name="patent"
                  onChange={handleInputChange}
                  value={formData.patent}
                  disabled={loading}
                >
                  <option value="1">Да</option>
                  <option value="0">Нет</option>
                  <option value="2">Частично</option>
                </select>
              </div>
            </div>
  
            <div className="row">
              <div className="transfer short">
                <label htmlFor="transfer">Оплата проезда</label>
                <select
                  name="transfer"
                  onChange={handleInputChange}
                  value={formData.transfer}
                  disabled={loading}
                >
                  <option value="1">Да</option>
                  <option value="0">Нет</option>
                  <option value="2">Частично</option>
                </select>
              </div>
  
              <div className="insurance short">
                <label htmlFor="insurance">Премия</label>
                <select
                  name="insurance"
                  onChange={handleInputChange}
                  value={formData.insurance}
                  disabled={loading}
                >
                  <option value="1">Да</option>
                  <option value="0">Нет</option>
                </select>
              </div>
            </div>
  
            <div className="row">
              <div className="notes long">
                <label htmlFor="notes">Примечание по вакансии</label>
                <input
                  type="text"
                  name="notes"
                  onChange={handleInputChange}
                  value={formData.notes}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
  
          {!updateMode ? (
            <div className="buttons-block" onClick={handleSubmit}>
              <div className="button create-button">
                Создать
              </div>
            </div>
          ) : formData.isArchived ? (
            <div className='buttons'>
              <div className="button" onClick={() => { handleUpdate(props.ad ?? 0) }}>Сохранить</div>
            </div>
          ) : (
            <div className='buttons'>
              <div className="button" onClick={() => { archiveAd(props.ad ?? 0) }}>Убрать в архив</div>
              <div className="button" onClick={() => { handleUpdate(props.ad ?? 0) }}>Сохранить</div>
            </div>
          )}
        </>
      )}
    </div>
  );
  
}

export default DetailsBar


