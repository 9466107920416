import React, { useState } from 'react'
import { ActiveTab } from '../Ads/Ads';

// CSS
import "./TabController.css"

interface TabControllerProps {
  activeTab: ActiveTab;
  setActiveTab: React.Dispatch<React.SetStateAction<ActiveTab>>;
}


const TabController:React.FC<TabControllerProps> = (props:TabControllerProps) => {

  return (
    <div className="tab-controller">
    <div
      className={`tab ${props.activeTab === 'active' ? 'active' : ''}`}
      onClick={() => props.setActiveTab(ActiveTab.Active)}
    >
      Активные
    </div>
    <div
      className={`tab ${props.activeTab === 'archived' ? 'active' : ''}`}
      onClick={() => props.setActiveTab(ActiveTab.Archived)}
    >
      Архивные
    </div>
    <div className={`bubble ${props.activeTab === 'active' ? 'left' : 'right'}`} >{props.activeTab === 'active' ? 'Активные' : 'Архивные'}</div>
  </div>
  )
}

export default TabController
