import React, { useState } from 'react';
import { IResponse } from '../Responses/Responses';
import backIco from '../../assets/images/back_ico.svg'
import SelectField from '../Select/SelectField';

// CSS
import './Filters.css';

interface IFiltersProps {
    onApplyFilters: () => void;
    responses: IResponse[] | [];
    filters: IFilters
    setFilters: React.Dispatch<React.SetStateAction<IFilters | {}>>
    filtersIsOpened:boolean
    setFiltersIsOpened: React.Dispatch<React.SetStateAction<boolean>>
}

interface IFilters {
    id?: number
    sex?: string;
    experience?: string;
    nation?: string;
    name?: string;
    ageFrom?: string;
    ageTo?: string;
    adId?: number
}

const Filters: React.FC<IFiltersProps> = (props: IFiltersProps) => {

    // Получаем уникальные adId
    const uniqueAds = Array.from(
        new Set(props.responses.map(response => response.adId))
    ).map(adId => props.responses.find(response => response.adId === adId)!);

    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

            props.setFilters(prevFilters => ({
                ...prevFilters,
                [name]: name === 'adId' ? +value : value,
            }));
    };

    const handleApplyFilters = () => {
        props.onApplyFilters()
        props.setFiltersIsOpened(false)
    };

    const handleResetFilters = () => {
        props.setFilters({});
        props.onApplyFilters()
        props.setFiltersIsOpened(false)
    };

    return (
        <div className={`details-bar-wrapper ${props.filtersIsOpened ? 'open' : ''}`}>
            <div className="details-bar-back-button">
                <img
                src={backIco}
                alt="back"
                onClick={() => props.setFiltersIsOpened(false)}
                />
                <span>Фильтры</span>
            </div>

            <div className="row">
                <div className="long">
                    <label htmlFor="adId">По объявлению</label>
                    
                    <select name="adId" value={props.filters.adId || ''} onChange={handleInputChange}>
                        <option >Все</option>
                        {uniqueAds.map((response) => (
                            <option key={response.adId} value={response.adId}>
                                {'Уборка ' + response.roomType + ` (id${response.adId})`}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="filters-content">
                <div className="row">
                    <div className="short">
                        <label htmlFor="sex">Пол</label>
                        <select name="sex" value={props.filters.sex || ''} onChange={handleInputChange}>
                            <option value="">Не важно</option>
                            <option value="1">Мужчина</option>
                            <option value="2">Женщина</option>
                        </select>
                    </div>

                    <div className="short">
                        <label htmlFor="experience">Опыт</label>
                        <select name="experience" value={props.filters.experience || ''} onChange={handleInputChange}>
                            <option value="">Не важно</option>
                            <option value="1">От 1 года</option>
                            <option value="2">От 2х лет</option>
                            <option value="3">От 3х лет и более</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="long">
                        <label htmlFor="nation">Гражданство</label>
                        {/* <select name="nation" value={props.filters.nation || ''} onChange={handleInputChange}>
                            <option value="">Не важно</option>
                            <option value="1">Россия</option>
                            <option value="2">Узбекистан</option>
                            <option value="3">Таджикистан</option>
                            <option value="4">Киргизия</option>
                            <option value="5">Белоруссия</option>
                            <option value="6">Украина</option>
                            <option value="7">Казахстан</option>
                            <option value="8">Армения</option>
                        </select> */}
                        <SelectField<IFilters> 
                            options={[
                                {value: '0', label:'Россия'},
                                {value: '1', label:'Казахстан'},
                                {value: '2', label:'Таджикистан'},
                                {value: '3', label:'Узбекистан'},
                                {value: '4', label:'Киргизия'},
                                {value: '5', label:'Белоруссия'},
                                {value: '6', label:'Украина'},
                                {value: '7', label:'Армения'},
                                {value: '8', label:'Не важно'}
                            ]}
                            name='nation'
                            data={props.filters}
                            setData={props.setFilters}
                            loading={false}
                        />
                    </div>
                </div>
            </div>

            <div className="filter-buttons">
                <div className="button" onClick={handleResetFilters}>Сбросить</div>
                <div className="button" onClick={handleApplyFilters}>Применить</div>
            </div>
        </div>
    );
};

export default Filters;
