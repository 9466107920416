import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import Container from '../Components/Container/Container';

// CSS
import "./AuthenticatedLayout.css"

const AuthenticatedLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Container>
        <div className="application-content">
            <Navbar />
            {children}
        </div>
    </Container>
  );
};

export default AuthenticatedLayout;
