import React, { useState, useEffect } from 'react';
import api from '../../api';
import City from '../City/City';
import { useToast } from '../../Context/ToastContext';
import Loader from '../Loader/Loader';

// CSS
import "./Account.css";

export interface ICompany {
  id: number;
  workPhone: string;
  name: string;
  inn: string;
  ownerName: string;
  cityId: number;
  mail: string;
}

const Account: React.FC = () => {
  const [company, setCompany] = useState<ICompany | null>(null);
  const [originalCompany, setOriginalCompany] = useState<ICompany | null>(null); // Исходное состояние компании
  const { notifySuccess, notifyError } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [logoName, setLogoName] = useState<string>('К')
  const [innSuggestions, setInnSuggestions] = useState<{ inn: string, name: string }[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);



  useEffect(() => {
    let logoString = company?.name
    let mainLetter = logoString?.slice(0,1).toLocaleUpperCase()

    if(mainLetter) {
      setLogoName(mainLetter)
    }
    
  }, [company?.name])


  useEffect(() => {
    // Загрузка данных профиля компании
    const fetchCompanyProfile = async () => {
      setLoading(true);

      try {
        const response = await api.get('/company/profile');
        setCompany(response.data);
        setOriginalCompany(response.data); // Сохраняем исходное состояние компании
        setLoading(false);
      } catch (error) {
        notifyError("Ошибка при загрузке профиля компании");
        console.error("Ошибка при загрузке профиля компании:", error);
        setLoading(false);
      }
    };

    fetchCompanyProfile();
  }, []);

  // Обработчик отправки данных при потере фокуса
  const handleBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {

    if (company && !validateField(company)) {
      return;
    }

    // Если данные не изменились, не отправляем запрос
    if (company && JSON.stringify(company) !== JSON.stringify(originalCompany)) {
      try {
        await api.put('/company/profile', company);
        setOriginalCompany(company); // Обновляем исходное состояние после успешного сохранения
        notifySuccess('Данные сохранены!');
      } catch (error) {
        console.error("Ошибка при обновлении профиля компании:", error);
        notifyError('Ошибка при обновлении профиля компании');
      }
    }
  };

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/<|>|&|\/|\\|%|;|'/g, '');

    // Обновляем состояние компании без валидации
    if (company) {
      setCompany({
        ...company,
        [name]: sanitizedValue,
      });
    }

    // Запрос на подсказки по ИНН
    if (name === 'inn' && sanitizedValue.length > 2) {
      fetchInnSuggestions(sanitizedValue);
    } else {
      setInnSuggestions([]);
      setShowSuggestions(false);
    }  

  };

  const fetchInnSuggestions = async (inn: string) => {
    try {
      const response = await api.get(`/inn?inn=${inn}`);
      setInnSuggestions(response.data);
      setShowSuggestions(true);
    } catch (error) {
      console.error("Ошибка при получении подсказок ИНН:", error);
      notifyError("Ошибка при получении подсказок ИНН");
    }
  };

  const handleSuggestionClick = (suggestion: { inn: string; name: string }) => {

    if (company) {
      setCompany({
        ...company,
        inn: suggestion.inn,
        name: suggestion.name
      });
    }

    setShowSuggestions(false);
  };

  const validateField = (company:ICompany): boolean => {
    

    for(let key in company) {
    
    // Проверка на номер телефона
    if (key === 'workPhone' && company[key]) {
      const phoneRegex = /^[\d\s\-+]+$/;
      if (!phoneRegex.test(company[key])) {
        notifyError('Неправильный формат номера телефона');
        return false;
      }
    }

    // Валидация email, но только если оно не пустое
    if (key === 'mail' && company[key]) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(company[key])) {
        notifyError('Неправильный формат email');
        return false;
      }
    }

    if(key === 'inn' && company[key] && company[key].length > 12) {
      notifyError(`Значение ИНН слишком длинное. Максимальная длина для ИНН: 12`);
      return false;
    }

    }

    return true;
  };

  return (
    <div className="account-content">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="company-data">
            <div className="logo"><span>{logoName}</span></div>
            <div className="company-data-info">
              <span className='user-name'>{company?.ownerName || 'Ваше имя'}</span>
              <span className='company-name'>{company?.name || 'Компания'}</span>
              <span className='company-city'>{selectedCity|| 'Город'}</span>
            </div>
          </div>
          <div className="company-inputs">

            <div className="row">
              <div className='long'>
                <label htmlFor="inn">ИНН</label>
                <input
                  type="text"
                  name='inn'
                  placeholder='ИНН'
                  value={company?.inn || ''}
                  onChange={inputHandler}
                  onBlur={handleBlur}
                />
                {showSuggestions && innSuggestions.length > 0 && (
                  <div className="suggestions-list">
                    {innSuggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestion-item"
                      >
                        {suggestion.inn} - {suggestion.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className='long'>
                <label htmlFor="name">Компания</label>
                <input
                  type="text"
                  name='name'
                  placeholder='Название Компании'
                  value={company?.name || ''}
                  onChange={inputHandler}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="row">
              <div className='long'>
                <label htmlFor="mail">Email (Для откликов)</label>
                <input
                  type="email"
                  name='mail'
                  placeholder='Ваш email'
                  value={company?.mail || ''}
                  onChange={inputHandler}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="row">
              <div className='long'>
                <label htmlFor="workPhone">Рабочий телефон</label>
                <input
                  type="phone"
                  name='workPhone'
                  placeholder='Рабочий телефон'
                  value={company?.workPhone || ''}
                  onChange={inputHandler}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="row">
              <div className='long'>
                <label htmlFor="ownerName">Ваше имя</label>
                <input
                  type="text"
                  name='ownerName'
                  placeholder='Ваше имя'
                  value={company?.ownerName || ''}
                  onChange={inputHandler}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <City company={company} setCompany={setCompany} selectedCity={selectedCity} setSelectedCity={setSelectedCity}/>

          </div>
        </>
      )}
    </div>
  );
}

export default Account;
