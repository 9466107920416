import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useBalance } from '../../Context/BalanceContext'
import api from '../../api'
import { useToast } from '../../Context/ToastContext'
import Loader from '../Loader/Loader'

// CSS
import "./Contact.css"

enum ContactStatus {
    NOT_RECEIVED = 0,   // Контакт не получен
    RECEIVED_NOT_ADDED = 1,   // Контакт получен, но не добавлен
    ADDED = 2   // Добавлено в контакты
}

interface IContactProps {
    phone: string
    userId: number
    adId: number
    status: ContactStatus
}

const Contact: React.FC<IContactProps> = (props:IContactProps) => {

  const [phone, setPhone] = useState(props.phone)
  const [status, setStatus] = useState<ContactStatus>(props.status)
  const { fetchBalance, setError, togglePopup } = useBalance()
  const { notifySuccess, notifyError } = useToast();
  const [loading, setLoading] = useState<boolean>(false)
  

  const getContact = async () => {
    setLoading(true)

    try {
        const response = await api.post(`/company/contact/${props.adId}`, {
            userId: props.userId
        })


        setPhone(response.data.phone)
        setStatus(ContactStatus.RECEIVED_NOT_ADDED)
        notifySuccess('Контакт куплен')
        setLoading(false)
        fetchBalance()
    } catch (error : any) {
        if(error.response.status === 421) {
            notifyError('У вас недостаточно средств')
            setError('У вас недостаточно средств')
            setLoading(false)
            togglePopup()
            return
        }

        console.log(error)
        notifyError('Произошла ошибка!')
    }
  }

  const addToContacts = async () => {
    setLoading(true)

    try {
        await api.post(`/company/contact/add/${props.adId}`, {
            userId: props.userId
        })
        setStatus(ContactStatus.ADDED)  // Обновляем статус на "Добавлено в контакты"
        notifySuccess('Контакт добавлен в избранное')
        setLoading(false)
    } catch (error) {
        console.error(error)
        notifyError('Произошла ошибка при добавлении в контакты!')
        setLoading(false)
    }
}

const removeFromContacts = async () => {
    setLoading(true)

    try {
        await api.post(`/company/contact/remove/${props.adId}`, {
            userId: props.userId
        })
        setStatus(ContactStatus.RECEIVED_NOT_ADDED)  // Обновляем статус на "Контакт не получен"
        notifySuccess('Контакт удален')
        setLoading(false)

    } catch (error) {
        console.error(error)
        notifyError('Произошла ошибка при удалении контакта!')
        setLoading(false)
    }
}

const handleButtonClick = () => {
    if (status === ContactStatus.NOT_RECEIVED) {
        getContact()
    } else if (status === ContactStatus.RECEIVED_NOT_ADDED) {
        addToContacts()
    } else if (status === ContactStatus.ADDED) {
        removeFromContacts()
    }
}

const renderButtonLabel = () => {
    if (status === ContactStatus.NOT_RECEIVED) {
        return 'Получить контакт'
    } else if (status === ContactStatus.RECEIVED_NOT_ADDED) {
        return 'Добавить в контакты'
    } else if (status === ContactStatus.ADDED) {
        return 'Удалить контакт'
    }
}

const buttonStyle = () => {
    if (status === ContactStatus.NOT_RECEIVED) {
        return ''
    } else if (status === ContactStatus.RECEIVED_NOT_ADDED) {
        return 'received'
    } else if (status === ContactStatus.ADDED) {
        return 'added'
    }
}

  return (
    <div className='contact'>
        <div className="phone">
            <a href={`tel:+${phone}`}>+{phone}</a></div>
        <div className={`button ${buttonStyle()}`} onClick={handleButtonClick}>{loading ? <Loader /> : renderButtonLabel()}</div>
    </div>
  )
}

export default Contact
