import React, { createContext, useContext, useState, ReactNode } from 'react';

interface LoadingContextType {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  menuIsOpened: boolean
  toggleMenu: () => void

}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [menuIsOpened, setMenuIsOpened] = useState<boolean>(false)

  const setLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  //TODO Переделать в нормальное решение переименовать и так далее!!
  const toggleMenu = () => {
    setMenuIsOpened(prevState => !prevState)
  }

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading, menuIsOpened, toggleMenu }}>
      {children}
      {isLoading && <div className="loading-overlay"><div className="spinner"></div></div>}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
