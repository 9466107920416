import React, { useEffect, useState } from 'react';
import api from '../../api';
import './Address.css';
import { useToast } from '../../Context/ToastContext';

interface IAddressProps {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, geoLat?:number, geoLon?: number) => void;
    value: string | undefined;
    name: string;
    label: string;
    title: string;
    inputErrors?: boolean
}

interface IAddressSuggestion {
    value: string;
    unrestricted_value: string;
    data: {
        geo_lat: number;
        geo_lon: number;
    }
}

const Address: React.FC<IAddressProps> = (props: IAddressProps) => {
    const [suggestions, setSuggestions] = useState<IAddressSuggestion[]>([]);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const { notifySuccess, notifyError } = useToast();

    useEffect(() => {
        if (props.value) {
            setShowSuggestions(false);
        }
    }, []);

    useEffect(() => {
        if (search.length >= 3) {
           const fetchSuggestions = async () => {
                try {
                    const response = await api.get(`/address?address=${search}`);
                    setSuggestions(response.data);
                    setShowSuggestions(true);
                } catch (error) {
                    notifyError("Ошибка при загрузке подсказок адреса");
                }
            };

            fetchSuggestions();
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    }, [search]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        props.handleInputChange(e);
        // Показать подсказки, если значение очищается или длина строки поиска достаточна
        if (e.target.value.length >= 3) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleSuggestionSelect = (suggestion: IAddressSuggestion) => {
        // setSearch(suggestion.value);
        setShowSuggestions(false);
        props.handleInputChange({ target: { name: props.name, value: suggestion.value} } as React.ChangeEvent<HTMLInputElement>, 
            suggestion.data.geo_lat,
            suggestion.data.geo_lon);
    };

    return (
        <div className="row">
            <div className="address long">
                <label htmlFor={props.label}>{props.title}</label>
                <input 
                    className={props.inputErrors ? 'input-error' : ''}
                    type="text" 
                    name={props.name} 
                    onChange={handleInputChange} 
                    value={props.value ?? search} 
                    autoComplete="off"
                    onBlur={() => setShowSuggestions(false)}
                    onFocus={() => search.length >= 3 && setShowSuggestions(true)}
                />
                {showSuggestions && suggestions.length > 0 && (
                    <div className="suggestions-dropdown">
                        {suggestions.map(suggestion => (
                            <div 
                                key={suggestion.value} 
                                className="suggestion-option" 
                                onMouseDown={() => handleSuggestionSelect(suggestion)}
                            >
                                {suggestion.value}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Address;
