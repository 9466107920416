import React from 'react';
import Select, { MultiValue, ActionMeta, StylesConfig } from 'react-select';

// Интерфейс для опций селекта
interface IOption {
  value: string;
  label: string;
}

// Обобщенный интерфейс для пропсов компонента
interface ISelectProps<T> {
  options: IOption[];
  data: T;
  setData: React.Dispatch<React.SetStateAction<T>>;
  name: keyof T;
  loading: boolean;
}

const SelectField = <T,>(props: ISelectProps<T>) => {
  const { name, options, data, setData, loading } = props;

  const handleChange = (selectedOptions: MultiValue<IOption>, actionMeta: ActionMeta<IOption>) => {
    setData(prevData => ({
      ...prevData,
      [name]: selectedOptions.map(option => option.value) as unknown as T[keyof T]
    }));
  };

  // Определяем значения по умолчанию
  const defaultValues = options.filter(option => {
    const values = data[name];
    return Array.isArray(values) && values.includes(+option.value);
  });

  // Если фильтрация не нашла подходящих значений, возвращаем значение '8'
  const finalDefaultValues = defaultValues.length > 0 ? defaultValues : options.filter(option => option.value === '8');

  const customStyles: StylesConfig<IOption, true> = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: '56px',
      padding: '0 6px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      height: '56px',
      border: '1px',
      borderColor: "solid #DDDEE2",
      borderRadius: '8px'
    }),
    // Закомментированные стили можно активировать при необходимости
    // indicatorSeparator: state => ({
    //   display: 'none',
    // }),
    // indicatorsContainer: (provided, state) => ({
    //   ...provided,
    //   height: '30px',
    // }),
  };

  return (
    <Select
      options={options}
      defaultValue={finalDefaultValues}
      onChange={handleChange}
      isDisabled={loading}
      styles={customStyles}
    />
  );
};

export default SelectField;
