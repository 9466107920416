// api.ts
import axios from 'axios';
import { useAuth } from './Context/AuthContext';
import config from './config'

const api = axios.create({
  baseURL: config.baseURL
});

// Интерсептор для добавления токена в запросы
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('cleanetAuthToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Интерсептор для обработки ответов
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      // Вызов функции logout из AuthContext
      const { logout } = useAuth(); // Доступ к функции logout из контекста
      logout(); // Разавторизация пользователя
    }
    return Promise.reject(error);
  }
);

export default api;
