import React, {ReactNode} from "react"
import "./Container.css"

interface ContainerProps {
    children: ReactNode;
  }

const Container:React.FC<ContainerProps> = (props) => {
	return <div className="container">{props.children}</div>
}

export default Container
