import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useToast } from '../../Context/ToastContext';
import Loader from '../Loader/Loader';
import Placeholder from '../Placeholder/Placeholder';
import CommentPopup from '../CommentPopup/CommentPopup';

// CSS
import "./Responses.css"

// Images
import locationIco from '../../assets/images/ad_location_ico.svg'

// Components
import Filters from '../Filters/Filters'
import Contact from '../Contact/Contact'
import api from '../../api'

export interface IResponse {
    id: number
    name: string
    age: number
    nation: string
    experience: number
    birthdate: Date
    sex: string
    adId: number
    status: number
    phone: string
    roomType: string
    address: string
    comment: string | undefined
}

interface IResponsesProps {
    me: boolean
}

export interface IFilters {
    sex?: string;
    experience?: string;
    nation?: string;
    name?: string;
    ageFrom?: string;
    ageTo?: string;
}

interface ISelectedResponse {
    adId: number;
    userId: number;
    comment?: string
}

const Responses: React.FC<IResponsesProps> = (props:IResponsesProps) => {
    const [responses, setResponses] = useState<IResponse[] | []>([])
    const [filters, setFilters] = useState<IFilters>({});
    const [useFilters, setUseFilters] = useState<number>(0)
    const location = useLocation()
    const { notifySuccess, notifyError } = useToast();
    const [loading, setLoading] = useState<boolean>(false)
    const [filtersIsOpened, setFiltersIsOpened] = useState<boolean>(false)
    const [commentPopupIsOpen, setCommentPopupIsOpen] = useState<boolean>(false)
    const [selectedResponse, setSelectedResponse] = useState<ISelectedResponse | null>(null);



    useEffect(() => {
        const fetchResponses = async () => {
            setLoading(true)

            try {
                
                const queryParams = new URLSearchParams({
                    me: String(props.me),
                    ...filters,
                }).toString();

                let result = await api.get(`/company/responses?${queryParams}`);
                setResponses(result.data.items);
                setLoading(false)
            } catch (error) {
                console.log(error);
                notifyError('Ошибка!');
                setLoading(false)
            }
        };

        fetchResponses();
    }, [props.me, useFilters]);

    useEffect(() => {
        // Извлекаем id из URL, если он есть
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');

        if (id) {
            setFilters(prevFilters => ({
                ...prevFilters,
                adId: id // Применяем id как фильтр
            }));

            setUseFilters(useFilters+1)
        }
    }, []);

    const getExperience = (experience:number):string => {
        switch (experience) {

            case 0:
                return "До 1 года"
            case 1:
                return `${experience} год`
            case 6:
                return `Более 5 лет`
            default:
                return `${experience} года`
        }
    }

    const getAge = (birthdate:Date):string => {
        const birthDate = new Date(birthdate);
        const now = new Date();
    
        let age = now.getFullYear() - birthDate.getFullYear();
        const monthDiff = now.getMonth() - birthDate.getMonth();
        const dayDiff = now.getDate() - birthDate.getDate();
    
        // Учитываем, прошел ли день рождения в этом году
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }
    
        return `${age} лет`;
    }

    const handleApplyFilters = () => {
        setUseFilters(useFilters+1)
    };

    const toggleFilters = () => {
        setFiltersIsOpened(prevState => !prevState);
    }

    const handleCommentPopup = (adId: number, userId: number, comment:string | undefined='') => {
        setSelectedResponse({adId, userId, comment})
        setCommentPopupIsOpen(true)
    }

  return (
    <div className='content-container'>
        <div className="content-wrapper">
            {/* <div className="response-filters">
                <input type="text" />
            </div> */}

            <button className='btn create-add-button' onClick={toggleFilters}>Фильтры</button>
            
            <div className="responses">

                {responses.length === 0 && !loading && (
                    <Placeholder  title='Здесь пока пусто' buttonTitle='Создать'/>
                )}

                {loading ? <Loader /> : ( 

                    responses?.map((response, index) => (
                        <div className="response" key={index}>
                            <div>
                                <div className="personal-data">
                                        <div className='ad-id'>id: {response.adId}</div>
                                        <div className="personal">
                                                <div className="nation"><span>Гражданство: {response.nation},</span><span> {getAge(response.birthdate) ?? ''}</span></div>
                                                <div className="age">{response.age}</div>
                                        </div>
                                        <div className="name">{response.name}</div>
                                        <div className="experience">Опыт: {getExperience(response.experience)}</div>
                                        <div className='ad-address'><img src={locationIco} alt="location" />{' ' + response.address}</div>
                                </div>
                                <Contact phone={response.phone}  adId={response.adId} userId={response.id} status={response.status}/>
                            </div>
                            {props.me ? (response.comment ? <div className='ad-comment-block' onClick={() => handleCommentPopup(response.adId, response.id, response.comment)}>{response.comment}</div> : <div className='add-comment-btn' onClick={() => handleCommentPopup(response.adId, response.id)}>Добавить комментарий</div>) : ''}
                        </div>
                    ))
                )}
            </div>
            
            {/* Попап для ввода коммента */}
            {commentPopupIsOpen && selectedResponse ? <CommentPopup setCommentPopupIsOpen={setCommentPopupIsOpen} adId={selectedResponse?.adId} userId={selectedResponse?.userId} comment={selectedResponse.comment} responses={responses} setResponses={setResponses}/> : ''} 

        </div>
        <Filters onApplyFilters={handleApplyFilters} responses={responses} filters={filters} setFilters={setFilters} filtersIsOpened={filtersIsOpened} setFiltersIsOpened={setFiltersIsOpened}/>
    </div>
     
  )
}

export default Responses
