import React, { useState } from 'react'
import Balance from '../Balance/Balance'
import { useAuth } from '../../Context/AuthContext';
import { useLoading } from '../../Context/LoadingContext';

// CSS
import "./Header.css"

// Images
import cleanetLogo from "../../assets/images/logo_no_text.svg";


const Header: React.FC = () => {

  const { isAuthenticated } = useAuth();
  const {toggleMenu, menuIsOpened} = useLoading()


  return (
		<div className="header">
			{isAuthenticated ? (
			<div className={`burger-menu ${menuIsOpened ? 'open' : ''}`} onClick={() => {toggleMenu()}}>
				<div></div>
				<div></div>
				<div></div>
			</div>
			) : ''}
			
			<div className="header-logo">
				<img src={cleanetLogo} alt="Cleanet Logo" />
				<div className="name">cleanet</div>
			</div>
			{isAuthenticated ? (<Balance />) : ''}
		</div>
  )
}

export default Header
