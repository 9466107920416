import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../Context/AuthContext'
import { useLoading } from '../../Context/LoadingContext'

// CSS
import "./Navbar.css"

// Img
import accIco from '../../assets/images/menu_acc_ico.svg'
import adIco from '../../assets/images/menu_ads_ico.svg'
import resIco from '../../assets/images/menu_res_ico.svg'
import emplIco from '../../assets/images/menu_empl_ico.svg'
import exitIco from '../../assets/images/menu_exit_ico.svg'
import tgIco from '../../assets/images/tg-ico.svg'
import phoneIco from '../../assets/images/phone-ico.svg'


const Navbar: React.FC = () => {
  const location = useLocation()
  const isActive = (path: string) => location.pathname === path ? 'active' : '';
  const { logout } = useAuth()
  const {toggleMenu, menuIsOpened} = useLoading()

  
  const handleLogout = () => {
    const confirmed = window.confirm('Вы уверены, что хотите выйти?');
    if (confirmed) {
      logout();
    }
  };

  return (
    <div className={`navbar mobile ${menuIsOpened ? 'open' : ''}`}>
        <div className="navbar-buttons">
            <ul onClick={()=>toggleMenu()}>
                <li className={isActive('/account')}><Link to={'/account'}><img src={accIco} alt="Иконка аккаунта" />Мой аккаунт</Link></li>
                <li className={isActive('/ads')}><Link to={'/ads'}><img src={adIco} alt="Иконка объявлений" />Мои объявления</Link></li>
                <li className={isActive('/responses')}><Link to={'/responses'}><img src={resIco} alt="Иконка откликов" />Мои отклики</Link></li>
                <li className={isActive('/contacts')}><Link to={'/contacts'}><img src={emplIco} alt="Работники" />Мои контакты</Link></li>
            </ul>
        </div>
        <div className="extra-buttons">
          <a href="https://t.me/Cleanet_bot" target='_blank'><div className=""><img src={tgIco} alt="" />@cleanet_bot
          </div></a>
          <a href="tel:+74952413282"><div className=""><img src={phoneIco} alt="" />+7 (495) 241-32-82</div></a>
          <div className="logout-button" onClick={handleLogout}><img src={exitIco} alt="" />Выйти</div>
        </div>
    </div>
  )
}

export default Navbar
