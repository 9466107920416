// src/contexts/ToastContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Создаем интерфейс для контекста
interface ToastContextType {
  notifySuccess: (message: string) => void;
  notifyError: (message: string) => void;
}

// Создаем контекст
const ToastContext = createContext<ToastContextType | undefined>(undefined);

// Провайдер для контекста
export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const notifySuccess = (message: string) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored"
    });
  };

  const notifyError = (message: string) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored"
    });
  };

  return (
    <ToastContext.Provider value={{ notifySuccess, notifyError }}>
        <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
        theme="colored"
      />
      {children}
    </ToastContext.Provider>
  );
};

// Хук для использования контекста
export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
