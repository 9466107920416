import React, { useEffect, useState } from 'react';
import api from '../../api';
import { ICompany } from '../Account/Account';
import { useToast } from '../../Context/ToastContext';


// CSS
import "./City.css";

interface ICity {
    id: number;
    city: string;

}

interface ICityProps {
    company: ICompany | null;
    setCompany: React.Dispatch<React.SetStateAction<ICompany | null>>;
    selectedCity: string
    setSelectedCity: React.Dispatch<React.SetStateAction<string>>;
}

const City: React.FC<ICityProps> = (props: ICityProps) => {
    const [filteredCities, setFilteredCities] = useState<ICity[]>([]);
    const [cities, setCities] = useState<ICity[]>([]);
    const [search, setSearch] = useState<string>('');
    const { notifySuccess, notifyError } = useToast();

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await api.get('/cities');
                setCities(response.data);
                setFilteredCities(response.data); // Изначально все города отображаются
            } catch (error) {
                notifyError("Ошибка при загрузке списка городов")
            }
        };

        fetchCities();
    }, []); // Запрос выполняется при монтировании компонента

    useEffect(() => {
        // Подстановка города после загрузки городов и данных компании
        if (props.company && cities.length > 0) {
            const city = cities.find(city => city.id === props.company?.cityId);
            if (city) {
                props.setSelectedCity(city.city);
            }
        }
    }, [props.company, cities]); // Зависимости - данные компании и список городов

    useEffect(() => {
        // Фильтрация городов на основе строки поиска
        setFilteredCities(
            cities.filter(city =>
                city.city.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, cities]);

    const handleCitySelect = async (city: ICity) => {
        if (props.company) {
            const updatedCompany = {
                ...props.company,
                cityId: city.id,
            };
            props.setCompany(updatedCompany);
            props.setSelectedCity(city.city);
            setSearch('');

            // Отправка обновленного объекта на сервер сразу после выбора города
            try {
                await api.put('/company/profile', updatedCompany);
                notifySuccess("Профиль обновлен успешно");
                
            } catch (error) {
                notifyError("Ошибка при обновлении профиля компании");
            }
        }
    };

    return (
        <div className="row">
            <div className='long'>
                <label htmlFor="citySearch">Город</label>
                <input 
                    type="text" 
                    name='citySearch' 
                    placeholder='Поиск города' 
                    value={search || props.selectedCity}
                    onChange={(e) => setSearch(e.target.value)} 
                />
                {search && (
                    <div className="city-dropdown">
                        {filteredCities.map(city => (
                            <div 
                                key={city.id} 
                                className="city-option"
                                onClick={() => handleCitySelect(city)}
                            >
                                {city.city}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default City;
