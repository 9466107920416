import React, { useState, useEffect } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { useToast } from '../../Context/ToastContext';

// CSS
import "./Login.css";

// Images
import cleanetLogo from "../../assets/images/logo_no_text.svg";

const Login: React.FC = () => {
	const { login, isAuthenticated } = useAuth();
	const [isCodeSent, setIsCodeSent] = useState(false);
	const [phone, setPhone] = useState('+7'); // Начинаем с "+7"
	const [code, setCode] = useState('');
	const navigate = useNavigate(); 
	const { notifySuccess, notifyError } = useToast();
	const [loading, setIsLoading] = useState<boolean>(false)
	const [timer, setTimer] = useState<number>(0)

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/account');
		}
	}, []);

	useEffect(() => {

		let smstimer: NodeJS.Timeout;
		if (timer > 0) {
			smstimer = setInterval(() => {
				setTimer(prevTime => prevTime - 1);
			}, 1000);
		}
		return () => clearInterval(smstimer);
	}, [timer]);

	

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let input = e.target.value;
	
		// Удаляем все символы, кроме цифр
		input = input.replace(/[^\d]/g, '');
	
		// Проверяем, если пользователь попытался ввести "+" вручную
		if (e.target.value.includes('+') && !input.startsWith('+')) {
			input = input.replace(/\+/g, ''); // Убираем все плюсы, если они есть
		}
	
		// Принудительно добавляем "+" в начало, если его нет
		if (!input.startsWith('+')) {
			input = '+' + input;
		}
	
		// Ограничиваем длину номера телефона (например, до 15 символов)
		if (input.length > 15) {
			input = input.slice(0, 15);
		}
	
		setPhone(input);
	};
	

	const handleLogin = async () => {
		try {
			setIsLoading(true);
	
			// Убираем все символы, кроме цифр
			const formattedPhone = phone.replace(/[^\d]/g, '');
	
			const response = await api.post('/auth/phone', { phone: formattedPhone });
	
			if (response.data) {
				setIsCodeSent(true);
				setTimer(response.data.resendTimeoutSeconds)
			}
	
		} catch (error) {
			notifyError('Ошибка!');
		} finally {
			setIsLoading(false);
		}
	};


	const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Убираем все символы, кроме цифр
        const value = e.target.value.replace(/\D/g, '');
        // Ограничиваем длину ввода до 4 символов
        if (value.length <= 4) {
            setCode(value);
        }
    };

	const handleCode = async () => {
		const formattedPhone = phone.replace(/[^\d]/g, '');
		
		try {
			setIsLoading(true)
			const response = await api.post('company/auth/code', {
				phone: formattedPhone,
				code
			});

			if (response.data.token) {
				setIsLoading(false)
				login(response.data.token);
				navigate('/account');
			}
		} catch (error) {
			notifyError('Неверный код!')
			setIsLoading(false)
		}
	};

	return (
		<div className='login-wrapper'>
			<div className="login-form-wrapper">
				<div className="login-form">
					<div className="brand">
						<img src={cleanetLogo} alt="Cleanet logo" className="big-logo" />
						<span>cleanet</span>
					</div>
					
					<h3>Вход в личный кабинет</h3>
					{isCodeSent ? (
						<>
							<label htmlFor="code">Код проверки</label>
							<input name='code' value={code} onChange={handleCodeChange} className='code' disabled={loading}/>
							{timer > 0 ? (<div className='timer'>Отправить повторно {timer}</div>) : ''}
							<button className='btn' onClick={handleCode} disabled={loading} >Войти</button>
						</>
					) : (
						<>
							<label htmlFor="phone">Номер телефона </label>
							<input 
								name='phone' 
								type='tel' 
								value={phone} 
								onChange={handlePhoneChange}
								disabled={loading}
							/>
							<button className='btn' onClick={handleLogin} disabled={loading}>Получить код</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Login;
