import React, { useState } from 'react'

// CSS
import "./Placeholder.css"

interface IPlaceholder {
  actionButton?: any,
  buttonTitle: string,
  title:string
}

const Placeholder:React.FC<IPlaceholder> = (props:IPlaceholder) => {

  return (
    <div className='placeholder-wrapper'>
      <h3>{props.title}</h3>
      {/* <button className='btn create-add-button' onClick={props.actionButton}>
        {props.buttonTitle}
      </button> */}
    </div>
  )
}

export default Placeholder
