import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import api from '../../api'
import { useToast } from '../../Context/ToastContext'
import Loader from '../Loader/Loader';
import Placeholder from '../Placeholder/Placeholder';


// CSS
import "./Ads.css"

// Images
import calendarIco from '../../assets/images/ad_calendar_ico.svg'
import locationIco from '../../assets/images/ad_location_ico.svg'
import moneyIco from '../../assets/images/ad_money_ico.svg'
import metroIco from '../../assets/images/ad_metro_ico.svg'

// Components
import TabController from '../TabController/TabController'
import DetailsBar from '../DetailsBar/DetailsBar'

interface Coordinates {
  lat: number,
  lon: number
}

export interface IAd {
  id: number;
  type: number
  name: string;
  adType: number;
  daySalary: number;
  monthSalary: number;
  dates: string;
  startDate: Date;
  endDate: Date;
  timeFrom: string;
  timeTo: string;
  language: string;
  hours: string;
  experience: number;
  shift: string;
  roomType: string;
  address: string;
  pickPoint: string;
  coordinates: Coordinates;
  rusRequired: string;
  clothes: string;
  hotel: string;
  food: string;
  patent: string;
  transfer: string;
  insurance: string;
  nation: string;
  notes: string;
  cityId: number;
  createdAt: Date;
  updatedAt: Date;
  hasResponse: boolean;
  metro:  string;
  isArchived: boolean;
  sex: string;
}

export enum ActiveTab {
  Active = 'active',
  Archived = 'archived'
}

const Ads: React.FC = () => {
  // TODO Не забыть про пагинацию

  const [ads, setAds] = useState<IAd[]>([])
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Active);
  const [sideBarIsOpened, setSideBarIsOpened] = useState<boolean>(false)
  const [activeAd, setActiveAd] = useState<number | undefined>()
  const [refreshAds, setRefreshAds] = useState<number>(0)
  const { notifySuccess, notifyError } = useToast();
  const [loading, setLoading] = useState<boolean>(false)
  

  useEffect(() => {
    // Загрузка данных профиля компании
    const fetchCompanyAds = async () => {
      setLoading(true)

      try {
        const response = await api.get(`/company/ads/me?archive=${activeTab === ActiveTab.Active ? false : true}`);
        console.log(response)
        setAds(response.data.items);
        setLoading(false)
      } catch (error) {
        notifyError("Ошибка при загрузке профиля компании");
        setLoading(false)
      }
    };

    fetchCompanyAds();
  }, [activeTab, refreshAds]);

  const newAd = () => {
    setSideBarIsOpened(true)
    setActiveAd(undefined)
  }

  const editAd = (id:number) => {
    setSideBarIsOpened(true)
    setActiveAd(id)
  }

  return (
    <div className='content-container'>
      <div className="content-wrapper">
        <button className='btn create-add-button' onClick={newAd}>Создать новое объявление</button>
        <div className="content-wrapeer">
          <TabController activeTab={activeTab} setActiveTab={setActiveTab} />

          
          {ads.length === 0 && !loading && (
            <Placeholder actionButton={newAd} title='Нет активных объявлений' buttonTitle='Создать'/>
          )}

       

          <div className="ads">
            {loading ? < Loader /> : 
                (ads.map((ad: IAd) => (
                <div className="ad" key={ad.id} onClick={()=>editAd(ad.id)}>
                  <div className="description">
                    <div className='ad-id'>id: {ad.id}</div>
                    <div>
                      <span className='ad-name'>{ad.name}</span><span className={`ad-status ${ad.isArchived ? 'archive' : ''}`}>{ad.isArchived ? 'В архиве' : 'Активно'}</span>
                    </div>
                    <div> 
                      <span className='ad-price'><img src={moneyIco} alt="money" />{ad.type === 0 ? ad.daySalary : ad.monthSalary}</span><span className='ad-date'><img src={calendarIco} alt="calendar" />{ad.dates} {ad.hours}</span>
                    </div>
                    <div><span className='ad-address'><img src={locationIco} alt="location" />{ad.address}</span></div>
                    {ad.metro && (<div><span className='ad-metro'><img src={metroIco} alt="metro" />{ad.metro}</span></div>)}
                  </div>
                  <div className="buttons">
                    <div className="button" onClick={()=>editAd(ad.id)}>Редактировать</div>
                    <Link to={`/responses?id=${ad.id}`}><div className="button">Отклики по объявлению</div></Link>
                  </div>
                </div>
              )))
            }
          </div>
        </div>
      </div>
      <DetailsBar ad={activeAd} setActiveAd={setActiveAd} isActive={sideBarIsOpened} setSideBarIsOpened={setSideBarIsOpened} setRefreshAds={setRefreshAds} refreshAds={refreshAds}/>
    </div>
  );

}

export default Ads

   //TODO Подумать, как выпилить дневную/месячную зп