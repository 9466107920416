import React, { useEffect, useState } from 'react'
import { useToast } from '../../Context/ToastContext'
import api from '../../api'
import Loader from '../Loader/Loader'

// CSS
import "./CommentPopup.css"

// Interfaces
import { IResponse } from '../Responses/Responses'

interface ICommentPopupProps {
    setCommentPopupIsOpen:React.Dispatch<React.SetStateAction<boolean>>
    responses: IResponse[]
    setResponses:React.Dispatch<React.SetStateAction<IResponse[]>>
    adId: number
    userId: number
    comment: string | undefined
}


const CommentPopup: React.FC<ICommentPopupProps> = (props:ICommentPopupProps) => {
  const { notifySuccess, notifyError } = useToast();
  const [loading, setLoading] = useState<boolean>(false)
  const [comment, setComment] = useState<string | undefined>(props.comment)

  const inputHadler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let { value } = e.target
    setComment(value)
  }

  const submitComment = async (save:boolean) => {
    setLoading(true)

    try {
        let newComment = save ? comment : ''

        const response = await api.post('/company/ad/comment', {
            applicantId: props.userId,
            adId: props.adId,
            comment: newComment
        })

        setLoading(false)
        props.setCommentPopupIsOpen(false)
        notifySuccess('Комментарий сохранён успешно')

        //TODO Здесь обновляем ui
        props.setResponses(prevResponses => prevResponses.map(response => (
            (response.id === props.userId && response.adId === props.adId) 
            ? {...response, comment: newComment} 
            : response
        )))
    } catch (error) {
        setLoading(false)
        notifyError('Не удалось сохранить комментрий')
    }
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content comment">
        <div className="close-popup" onClick={() => {props.setCommentPopupIsOpen(false)}}>×</div>
        {loading ? <Loader/> : (
            <>
                <label htmlFor="comment">Введите комментарий</label>
                <textarea name="comment" placeholder='Комментарий' onChange={inputHadler} autoFocus>{comment}</textarea>
                <div className="buttons">
                    <div className="button" onClick={(e) => submitComment(true)}>Сохранить</div>
                    <div className="button delete"onClick={(e) => submitComment(false)}>Удалить</div>
                </div>
            </>
        )}
      </div>
    </div>
  )
}

export default CommentPopup;
